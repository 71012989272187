<template>
  <div class="naturalLanguage">
    <img src="../../assets/product/naturalLanguage/1.png" class="headerimg" />

    <div class="character">
      <div class="title">邦伲德自然语言处理的特性</div>

      <div class="characteristiclist">
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/naturalLanguage/2.png" />
            <div class="characteristictext">积累厚实</div>
          </div>
          <div class="text">
            在语言材料上，我们多年来专注中文自然语言处理，在不同场景下积累了互联网语料；技术上，我们整合了内部顶级的
            NLP
            能力，拥有相关技术专利和论文；服务上，团队曾为亿级用户产品提供坚实的服务支撑。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/naturalLanguage/3.png" />
            <div class="characteristictext">服务高效</div>
          </div>
          <div class="text">
            邦伲德计算平台保障服务高效稳定，可以根据不同的客户需求，提供数据动态扩容能力，并且配备完善及时的开发者支持，全心打造邦伲德人工智能和自然语言处理能力开放平台。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/naturalLanguage/4.png" />
            <div class="characteristictext">接口全面</div>
          </div>
          <div class="text">
            邦伲德自然语言处理提供了词法级、句法级、篇章级、向量级等数十个模块的文本处理能力，为您一站式地解决常见的中文语义分析需求，帮助您为日常工作或企业运营降本、增效和创新。
          </div>
        </div>
      </div>
    </div>

    <div class="application">
      <div class="title">应用场景</div>
      <div class="applicationtitle">用户UGC文本情感倾向分析</div>
      <div class="applicationimg">
        <img src="../../assets/product/naturalLanguage/5.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.naturalLanguage {
  .character {
    background-color: #f5f5f9;
    padding: 88px 0;
    margin: 0 auto;
    margin-top: 80px;
    .characteristiclist {
      display: flex;
      width: 80%;
      margin: auto;
      margin-top: 100px;
      justify-content: space-between;
      .characteristicinfo {
        width: 400px;
        .characteristicheard {
          text-align: center;
          font-size: 30px;
          font-weight: 500;
          line-height: 45px;
          img {
            width: 100px;
            height: 100px;
          }
          .characteristictext {
            margin-top: 36px;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 45px;
          }
        }
        .text {
          margin-top: 20px;
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 40px;
        }
      }
    }
  }

  .application {
    width: 80%;
    margin: 140px auto;
    .applicationtitle {
      font-size: 30px;
      font-weight: 500;
      line-height: 45px;
      margin-top: 80px;
      margin-left: 155px;
    }
    .applicationimg {
      text-align: center;
      margin-top: 140px;
      img {
        width: 1320px;
      }
    }
  }
  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }
}
</style>
